<template>
    <div>
        <b-card class="overflow-hidden bg-[#181C21] h-100" v-if="items.length > 0">
            <b-row class="match-height">
                <b-col v-for="(item, i) in items" :key="i" xl="4" lg="4" md="6" sm="12" class="mb-2">
                    <b-card class="cardStore cursor-pointer" overlay :style="'background:linear-gradient(1.04deg, #000000 2.47%, rgba(0, 0, 0, 0) 132.98%), url(' +
                        item.imageUrl +
                        '); height:280px;border-radius:20px;'
                        " img-alt="" text-variant="white" @click="purshasedDetail(item)">
                        <div class="position-absolute badgeCrown">
                            <b-img src="@/assets/playground_assets/usdc.png" fluid alt="" class="w-8 h-8" style="padding: 4px 4px"></b-img>
                            {{ item.price }}
                        </div>

                        <b-card-text class="position-absolute purchaseTitle">
                            {{ item.title }}
                        </b-card-text>
                        <b-card-text class="position-absolute badgeW" v-if="item.winner">
                            <b-img :src="require('@/assets/images/illustration/crown.svg')" fluid alt=""
                                style="width: 18px; height: 18px; margin-top: -5px" />
                            {{ item.winner }}
                        </b-card-text>
                        <b-card-text class="position-absolute timeago">
                            {{ item.stages[0].shortDate }}
                        </b-card-text>
                    </b-card>
                </b-col>
            </b-row>
        </b-card>
        <div class="min-h-96 flex items-center justify-center bg-[#181C21] rounded-lg mt-20" v-else>
            <div class="rounded-lg p-8 m-4 w-full lg:w-1/2">
                <h1 class="text-xl font-bold mb-4 text-center">Not item purchased yet</h1>
                <div class="flex justify-center space-x-4"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                        viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                        stroke-linejoin="round" class="w-8 h-8 text-white">
                        <path
                            d="M17.8 19.2 16 11l3.5-3.5C21 6 21.5 4 21 3c-1-.5-3 0-4.5 1.5L13 8 4.8 6.2c-.5-.1-.9.1-1.1.5l-.3.5c-.2.5-.1 1 .3 1.3L9 12l-2 3H4l-1 1 3 2 2 3 1-1v-3l3-2 3.5 5.3c.3.4.8.5 1.3.3l.5-.2c.4-.3.6-.7.5-1.2z">
                        </path>
                    </svg><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                        stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                        class="w-8 h-8 text-white">
                        <circle cx="12" cy="12" r="10"></circle>
                        <polyline points="12 6 12 12 16 14"></polyline>
                    </svg><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                        stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                        class="w-8 h-8 text-white">
                        <rect width="18" height="18" x="3" y="4" rx="2" ry="2"></rect>
                        <line x1="16" x2="16" y1="2" y2="6"></line>
                        <line x1="8" x2="8" y1="2" y2="6"></line>
                        <line x1="3" x2="21" y1="10" y2="10"></line>
                    </svg></div>
                <p class="text-center text-white mt-4">
                    You can purchase in the availables section
                </p>
            </div>
        </div>
        <!-- <b-modal v-model="openmodal" :title="$t('curlang') == 'en' ? 'Timeline' : 'Linea de Tiempo'" hide-footer centered>
            <app-timeline class="mb-1 mt-1">
                <app-timeline-item v-for="(stage, i) in stages" :key="i" :title="stage.stageName" :subtitle="$t('curlang') == 'en'
                    ? 'Your product have been ' + stage.stageName
                    : 'Tu compra realizada está ' + stage.stageName
                    " :time="stage.shortDate" :variant="variantTimeline(stage.stageName)" />
            </app-timeline>
        </b-modal> -->
    </div>
</template>
<script>
import {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BModal,
    BImg,
} from "bootstrap-vue";
import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";
import SellerService from "@/services/SellerService";
export default {
    components: {
        BCard,
        BCardHeader,
        BCardTitle,
        BCardBody,
        BTable,
        BAvatar,
        BBadge,
        BRow,
        BCol,
        BCardText,
        BFormGroup,
        BFormSelect,
        BPagination,
        BInputGroup,
        BFormInput,
        BInputGroupAppend,
        BButton,
        AppTimeline,
        AppTimelineItem,
        BImg,
    },
    data() {
        return {
            items: [],
            openmodal: false,
            stages: [],
        };
    },
    mounted() {
        this.getItempurchased();
    },
    methods: {
        getItempurchased: async function () {
            const gip = (
                await SellerService.getItempurchased(
                    JSON.parse(localStorage.getItem("auth")).discordId
                )
            ).data;
            this.items = gip;
        },
        purshasedDetail: function (item) {
            this.stages = [];
            this.openmodal = true;
            this.stages = item.stages;
        },
        variantTimeline(n) {
            if (n == "pending") {
                return "danger";
            }
            if (n == "approved") {
                return "warning";
            }
            if (n == "delivered") {
                return "success";
            }
            if (n == "rejected") {
                return "secondary";
            }
            if (n == "pendiente") {
                return "danger";
            }
            if (n == "aprobada") {
                return "warning";
            }
            if (n == "entregada") {
                return "success";
            }
            if (n == "rechazada") {
                return "secondary";
            }
        },
    },
};
</script>
  
<style lang="scss">
.bgcCustom {
    border-radius: 8px;
}

.purchaseTitle {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.015em;
    color: #ffffff;
    left: 10px;
    bottom: 15px;
    width: 255px;
}

.badgeCrown {
    display: flex;
    width: 93.69px;
    height: 40px;
    justify-content: center;
    align-items: center;
    background: #223433;
    border-radius: 4px;
    top: 5%;
    right: 5%;
    bottom: 20.31%;
    margin-top: 3.45%;
}

.badgeW {
    display: flex;
    width: 150px;
    height: 40px;
    justify-content: center;
    align-items: center;
    background: #223433;
    border-radius: 4px;
    margin-top: 3.45%;
    top: 5%;
}

.timeago {
    display: flex;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.015em;
    color: #9f9f9f;
    left: 2%;
    bottom: -10%;
}

.cardStore {
    overflow: visible;
}

.cardStore:hover {
    overflow: hidden;
    transform: scale(1.05);
    box-shadow: 0px 0px 10px #82868b !important;
}
</style>