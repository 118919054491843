<template>
    <div>
        <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4" v-if="item.length > 0">
            <div class="w-full h-full rounded-lg cursor-pointer shadow
        transition-all duration-700 bg-[#181C21] relative group" v-for="(ite, i) in item" :key="i">
                <img class="w-full h-60 rounded-t-lg" :src="ite.imageUrl" alt="Image for item" />
                <div class="flex flex-col justify-center items-center gap-1 mx-4 my-2">
                    <p class="text-2xl font-bold text-center text-white">
                        {{ ite.title }}
                    </p>
                </div>
                <div class="flex flex-row justify-between mx-2 my-1">
                    <div class="text-white/70 text-xl">Available</div>
                    <div :class="`${ite.stock > 0 ? 'text-white' : 'text-red-700'} text-xl`">
                        {{ numberWithCommas(ite.stock) }}
                    </div>
                </div>
                <div class="flex flex-row justify-between mx-2 mb-6">
                    <div class="text-white/70 text-xl">Price</div>
                    <div class="text-white text-xl flex flex-row gap-1 items-center">
                        <img src="@/assets/playground_assets/usdc.png" class="w-5 h-5" alt="">
                        {{ numberWithCommas(ite.price) }}
                    </div>
                </div>

                <div class="absolute inset-0 bg-[#181C21] opacity-0 transition-all duration-300 group-hover:opacity-80">
                </div>
                <div
                    class="absolute right-0 bottom-10 translate-y-4 transition-all duration-500 opacity-0 group-hover:translate-y-0 group-hover:opacity-100 z-10 w-full">
                    <div class="flex justify-between mx-1 gap-1">
                        <button class="bg-[#3dafd3] btnop w-full text-white text-lg rounded"
                            @click="openBuy(ite, 1)">Details</button>
                        <button class="bg-[#9b51e0] btnop w-full text-white text-lg rounded" @click="openBuy(ite, 2)"
                            v-if="ite.stock > 0">Buy</button>
                    </div>
                </div>
            </div>
        </div>
        <availabledetails :itembuy="itembuy" :showmodal="showmodal" :btnoption="btnoption" v-if="showmodal" />
    </div>
</template>
<script>
import availabledetails from "./availabledetails.vue"
const user = JSON.parse(localStorage.getItem("auth"));
export default {
    components: { availabledetails },
    props: ["item"],
    data() {
        return {
            userL: user,
            imageUrl: null,
            itembuy: null,
            showmodal: false,
            btnoption: 0,
        }
    },
    methods: {
        openBuy(i, op) {
            this.imageUrl = i.imageUrl;
            this.itembuy = {
                id: i._id,
                title: i.title,
                price: i.price,
                description: i.description,
                stock: i.stock,
                typeCoin: i.typeCoin.value,
                transactionid: null,
            };
            if (op == 2) {
                this.btnoption = 2
            }
            else {
                this.btnoption = 1
            }
            this.showmodal = true


        },
        numberWithCommas: function (x) {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
    },
}
</script>
<style>
.btnop {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
}
</style>