<template>
    <div>
        <div class="relative rounded-xl bg-[#181C21] p-10 w-full overflow-hidden">
            <div class="flex flex-col md:items-center ">
                <h1 class="text-2xl md:text-5xl font-semibold text-white mb-2 md:mb-4">Auto Farm</h1>
                <p class="text-base md:text-xl text-white/70 w-1/1 md:w-2/3 text-center">Register your wallets for automated
                    farming. The Empire will tend to the fields on your behalf. Our boots are on the ground and ready! </p>
            </div>

            <div class="absolute left-0 top-10">
                <img src="@/assets/images/illustration/Cart1.png" alt="" class=" opacity-5 lg:opacity-100" />
            </div>
        </div>
        <!-- <div class="min-h-96 flex items-center justify-center bg-[#181C21] rounded-lg mt-20">
            <div class="rounded-lg p-8 m-4 w-full lg:w-1/2">
                <h1 class="text-xl font-bold mb-4 text-center">Coming Soon</h1>
                <div class="flex justify-center space-x-4"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                        viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                        stroke-linejoin="round" class="w-8 h-8 text-white">
                        <path
                            d="M17.8 19.2 16 11l3.5-3.5C21 6 21.5 4 21 3c-1-.5-3 0-4.5 1.5L13 8 4.8 6.2c-.5-.1-.9.1-1.1.5l-.3.5c-.2.5-.1 1 .3 1.3L9 12l-2 3H4l-1 1 3 2 2 3 1-1v-3l3-2 3.5 5.3c.3.4.8.5 1.3.3l.5-.2c.4-.3.6-.7.5-1.2z">
                        </path>
                    </svg><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                        stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                        class="w-8 h-8 text-white">
                        <circle cx="12" cy="12" r="10"></circle>
                        <polyline points="12 6 12 12 16 14"></polyline>
                    </svg><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                        stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                        class="w-8 h-8 text-white">
                        <rect width="18" height="18" x="3" y="4" rx="2" ry="2"></rect>
                        <line x1="16" x2="16" y1="2" y2="6"></line>
                        <line x1="8" x2="8" y1="2" y2="6"></line>
                        <line x1="3" x2="21" y1="10" y2="10"></line>
                    </svg></div>
                <p class="text-center text-white mt-4">
                    We are currently working on something awesome. Stay tuned!
                </p>
            </div>
        </div> -->

        <b-tabs class="mt-20" content-class="mt-20" pills
            :fill="['sm', 'xs'].includes($store.getters['app/currentBreakPoint']) ? true : false">
            <b-tab disabled v-if="['sm', 'xs'].includes($store.getters['app/currentBreakPoint']) ? false : true">
                <template #title>
                    <h5 class="font-weight-bolder text-white d-none d-md-block" style="margin-top:-5px;">Auto Farm</h5>
                </template>
            </b-tab>
            <b-tab active>
                <template #title>
                    <span>Availables</span>
                </template>
                <SkeletonCard v-if="loading" />
                <available :item="items" v-else />
            </b-tab>
            <b-tab>
                <template #title>
                    <span>Purchased</span>
                </template>
                <purchased />
            </b-tab>
            <b-tab>
                <template #title>
                    <span>Reports</span>
                </template>
                <reports />
            </b-tab>

        </b-tabs>
    </div>
</template>
<script>
import SellerService from '@/services/SellerService'
import SkeletonCard from "@/components/SkeletonCard.vue";
import available from "./available.vue"
import { BTabs, BTab } from "bootstrap-vue"
import purchased from "./purchased.vue"
import reports from "./reports.vue"



export default {
    components: { SkeletonCard, available, BTabs, BTab, purchased, reports },
    data() {
        return {
            items: [],
            loading: true
        }
    },
    mounted() {
        this.getItemsStock()
    },
    methods: {
        getItemsStock: async function () {
            const it = (await SellerService.getItemsStock(JSON.parse(localStorage.getItem("auth")).discordId)).data
            this.items = it
            this.loading = false
        }
    }
};
</script>