<template>
    <div>
        <b-modal ref="modal-item" v-model="showmodal" title="Farm Detail" hide-footer>

            <div class="flex justify-between mt-10 gap-20 mx-1">
                <div class="text-xl font-bold text-white">{{ itembuy.title }}
                </div>
                <div class="flex flex-row gap-1">
                    <img src="@/assets/playground_assets/usdc.png" class="w-5 h-5" alt="">
                    <div class="text-white">{{ numberWithCommas(itembuy.price) }}</div>
                </div>
            </div>
            <div class="mt-4 mb-2" v-html="itembuy.description" v-if="btnoption == 1">

            </div>
            <div class="mt-4 mb-2" v-if="btnoption == 2">
                <b-row>
                    <b-col cols="12">
                        <b-form-group label="Send USDC funds to">
                            <b-row>
                                <b-col lg="9" xl="9">
                                    <b-form-input v-model="addressusdc" type="text" disabled />
                                </b-col>
                                <b-col lg="3" xl="3">
                                    <b-button pill v-clipboard:copy="addressusdc" v-clipboard:success="onCopy"
                                        v-ripple.400="'rgba(186, 191, 199, 0.15)'" variant="primary">
                                        Copy!
                                    </b-button>
                                </b-col>
                            </b-row>
                        </b-form-group>
                    </b-col>
                    <b-col cols="12">
                        <b-form-group label="Transaction ID">
                            <b-form-input v-model="itembuy.transactionid" type="text"
                                placeholder="Paste the Transaction ID" />
                        </b-form-group>
                        <!-- <b-form-group label="Discord ID">
                            <b-form-input v-model="discordid" type="text" placeholder="Enter your Discord ID" disabled />
                        </b-form-group> -->
                    </b-col>
                </b-row>
                <div class="mt-2 mb-2">
                    <b-button variant="success" block pill :disabled="!itembuy.transactionid" @click="enrollUser">
                        <div class="flex flex-row gap-1 items-center justify-center">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                v-if="enroll">
                                <path fill="currentColor"
                                    d="M12 2A10 10 0 1 0 22 12A10 10 0 0 0 12 2Zm0 18a8 8 0 1 1 8-8A8 8 0 0 1 12 20Z"
                                    opacity=".5" />
                                <path fill="currentColor" d="M20 12h2A10 10 0 0 0 12 2V4A8 8 0 0 1 20 12Z">
                                    <animateTransform attributeName="transform" dur="1s" from="0 12 12"
                                        repeatCount="indefinite" to="360 12 12" type="rotate" />
                                </path>
                            </svg>
                            <div class="text-white font-bold text-xl">Enroll Wallet</div>
                        </div>
                    </b-button>
                </div>
            </div>
        </b-modal>
    </div>
</template>
<script>
import { BCardTitle, BCardSubTitle, BCardText, BRow, BCol, BBadge, BFormGroup, BFormInput, BModal, BButton } from "bootstrap-vue"
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import SellerService from "@/services/SellerService";
export default {
    props: ["itembuy", "showmodal", "btnoption"],
    components: {
        BCardTitle, BCardSubTitle, BCardText, BRow, BCol, BBadge,
        BFormGroup, BFormInput, BModal, BButton, ToastificationContent
    },
    directives: { Ripple },
    data() {
        return {
            addressusdc: 'C8aduKCDnRBWq7LHS4bVEEAp556neERj7F6X2UeeSsVf',
            discordid: JSON.parse(localStorage.getItem("auth")).discordId,
            enroll: false

        }
    },
    methods: {
        enrollUser: async function () {
            this.enroll = true
            await SellerService.InsertBuyItem(this.discordid, this.itembuy).then((resu) => {
                console.log(resu);
                this.$refs["modal-item"].hide();
                this.enroll = false
                this.$swal({
                    title: resu.data == 0 ? "Error" : "Success",
                    text: resu.data == 0 ? "Enroll Wallet failed" : "Enroll Wallet Saved Successfull",
                    icon: resu.data == 0 ? "error" : "success",
                    allowOutsideClick: false,
                    showClass: {
                        popup: "animate__animated animate__fadeIn",
                    },
                    customClass: {
                        confirmButton: resu.data == 0 ? "btn btn-danger" : "btn btn-success",
                    },
                    buttonsStyling: false,
                }).then(result => {
                    if (result.value) {
                        this.$router.go(0)
                    }
                });
            })
        },
        numberWithCommas: function (x) {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
        onCopy() {
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: 'Text copied',
                    icon: 'BellIcon',
                },
            })
        },

    }
}
</script>