<template>
    <div class="max-w-[56rem] mx-auto bg-[#181C21] p-4 rounded-lg mt-10">
        <div v-if="loading">
            <div class="mx-auto h-4 bg-gray-400 rounded-full dark:bg-gray-700 w-48 max-w-sm animate-pulse"></div>
            <div class="flex justify-between py-4">
                <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-700 w-48 max-w-sm animate-pulse"></div>
                <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-700 w-48 max-w-sm animate-pulse"></div>

            </div>
            <div class="flex justify-between">
                <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-700 w-48 max-w-sm animate-pulse"></div>
                <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-700 w-48 max-w-sm animate-pulse"></div>

            </div>
        </div>
        <div v-else>
            <div class="text-white text-3xl font-semibold text-center py-2">Report List ({{ reports.length }})</div>
            <ul class=" divide-y divide-white" v-if="reports.length > 0">
                <li class="py-1" v-for="(report, i) in reports" :key="i">
                    <div class="flex justify-between">
                        <div class="flex-1 min-w-0 text-white text-base font-semibold">
                            {{ report.fileName }}
                        </div>
                        <div @click="downloadReport(report._id.$oid)"
                            class="inline-flex items-center text-base font-semibold hover:cursor-pointer underline text-white/80">
                            Download
                        </div>
                    </div>
                </li>

            </ul>
            <div class="text-center text-lg text-white/90" v-else>
                <p>You dont have any reports yet!</p>
            </div>
        </div>

    </div>
</template>
<script>
import UserService from '@/services/UserService';
export default {
    data() {
        return {
            reports: [],
            loading: true
        }
    },
    mounted() {
        this.getReports()
    },
    methods: {
        getReports: async function () {
            await UserService.getReportsByUser(JSON.parse(localStorage.getItem(('auth')))).then((res) => {
                this.reports = res
                this.loading = false
            }).catch((error) => {
                console.log(error);
            })
        },
        downloadReport: async function (id) {
            const idi = await UserService.getID(JSON.parse(localStorage.getItem('auth')))
            const link = document.createElement("a");
            link.href = "/api/v1/cadfarm/" + id + '/' + idi
            link.setAttribute("download", 'CollectionHolders.csv');
            document.body.appendChild(link);
            link.click();
        }
    }
}
</script>